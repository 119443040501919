/* footer section starts */
.footer {
    min-height: auto;
    padding-top: 0;
    background: rgb(0, 1, 43);
  }
  .footer .box-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .footer .box-container .box {
    flex: 1 1 25rem;
    margin: 2.5rem;
  }
  .footer .box-container .box h3 {
    font-size: 2.5rem;
    color: #fff;
    padding-bottom: 1rem;
    font-weight: normal;
  }
  .footer .box-container .box p {
    font-size: 1.5rem;
    color: #ccc;
    padding: 0.7rem 0;
    text-transform: none;
  }
  .footer .box-container .box p i {
    padding-right: 1rem;
    color: #ffae00;
  }
  .footer .box-container .box a {
    font-size: 1.5rem;
    color: rgb(238, 238, 238);
    padding: 0.3rem 0;
    display: block;
  }
  .footer .box-container .box a:hover {
    color: #ffae00;
  }
  .footer .box-container .box .share {
    display: flex;
    flex-wrap: wrap;
    padding: 1rem 0;
  }
  .footer .box-container .box .share a {
    height: 4rem;
    width: 4rem;
    padding: 1rem;
    text-align: center;
    border-radius: 5rem;
    font-size: 1.7rem;
    margin-right: 1rem;
    transition: 0.2s;
    background: rgb(230, 230, 230);
    color: #02094b;
    border: none;
  }
  .footer .box-container .box .share a:hover {
    background: transparent;
    transform: scale(0.98);
    border: 0.1rem solid rgb(180, 178, 178);
    color: #ffae00;
  }
  .footer .credit {
    padding: 1rem 0 0 0;
    text-align: center;
    font-size: 1.5rem;
    font-family: "Nunito", sans-serif;
    font-weight: 600;
    color: #fff;
    border-top: 0.1rem solid #fff3;
  }
  .footer .credit a {
    color: #ffae00;
  }
  .footer .fa {
    color: #e90606;
    margin: 0 0.3rem;
    font-size: 1.5rem;
    animation: pound 0.35s infinite alternate;
  }
  @-webkit-keyframes pound {
    to {
      transform: scale(1.1);
    }
  }
  @keyframes pound {
    to {
      transform: scale(1.1);
    }
  }
  @media (max-width: 450px) {
    .footer .box-container .box {
      margin: 1.5rem;
    }
    .footer .box-container .box p {
      padding: 0.7rem;
    }
    .footer .box-container .box .share a {
      padding: 1.2rem;
    }
  }
  /* footer section ends */
  