/* Navbar.css */
header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.7rem 10%;
    height: 6.5rem;
    background-color: #fff;
    box-shadow: 0 1px 4px rgba(146, 161, 176, 0.3);
}

section {
    min-height: 100vh;
    padding: 2rem 9%;
}

.heading {
    font-size: 3.5rem;
    color: rgb(32, 32, 32);
    font-weight: 800;
    text-align: center;
}

.heading span {
    color: rgb(115, 3, 167);
}

header .logo {
    font-size: 1.9rem;
    font-weight: 800;
    text-decoration: none;
    color: #0e2431;
}

header .logo i {
    font-size: 2.2rem;
}

header .logo:hover {
    color: #fc8c05;
}

header .navbar ul {
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

header .navbar li {
    margin-left: 2.5rem;
}

header .navbar ul li a {
    font-size: 1.57rem;
    color: #0e2431;
    font-weight: 600;
    text-decoration: none;
    letter-spacing: 0.04rem;
    transition: 0.2s;
}

header .navbar ul li a.active,
header .navbar ul li a:hover {
    color: #011aff;
    border-bottom: 0.2rem solid #011aff;
    padding: 0.5rem 0;
}


/* hamburger icon starts*/
#menu {
    font-size: 3rem;
    cursor: pointer;
    color: rgb(24, 2, 63);
    display: none;
}

@media (max-width: 768px) {
    #menu {
        display: block;
    }

    header .navbar {
        position: fixed;
        top: 6.5rem;
        right: -120%;
        width: 75%;
        height: 100%;
        text-align: left;
        align-items: flex-start;
        background-color: #0e0f31;
    }

    header .navbar ul {
        flex-flow: column;
        padding: 1rem;
    }

    header .navbar ul li {
        text-align: center;
        width: 100%;
        margin: 1rem 0;
        border-radius: 0.5rem;
        width: 26rem;
    }

    header .navbar ul li a {
        display: block;
        padding: 1rem;
        text-align: left;
        color: #fff;
        font-size: 2rem;
    }

    header .navbar ul li a.active,
    header .navbar ul li a:hover {
        padding: 1rem;
        color: #fff;
        border-radius: 0.5rem;
        border-bottom: 0.5rem solid #011aff;
    }

    .fa-times {
        transform: rotate(180deg);
    }

    header .navbar.nav-toggle {
        right: 0;
    }
}

/* hamburger icon ends */