/* Blog.css */

.blog-container {
    text-align: center;
    background-color: #ffffff;
    border-radius: 8px;
    padding: 20px;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.blog-title {
    color: #333;
}

.countdown-text {
    color: #666;
}

.back-button {
    background-color: #3498db;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 4px;
    margin-top: 10px;
    transition: background-color 0.3s ease;
}

.back-button:hover {
    background-color: #2980b9;
}