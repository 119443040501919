/* HeroSection.css */

.home {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  min-height: 100vh;
  align-items: center;
}

.Particles {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}


.home::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.home .content {
  flex: 1 1 40rem;
  padding-top: 1rem;
  z-index: 1;
}

.home .image {
  flex: 1 1 40rem;
  z-index: 1;
}

.home .image img {
  width: 70%;
  margin-left: 6rem;
  border-radius: 50%;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.home .image img:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}

.home .content h2 {
  font-size: 5rem;
  font-weight: 800;
  color: #002057;
}

.home .content h2 span {
  font-size: 5rem;
  font-weight: 800;
  color: #ff7b00;
}

.home .content p {
  font-size: 2.5rem;
  color: #000;
  font-weight: 600;
  padding: 1rem 0;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
}

.home .content p span {
  font-size: 2.5rem;
  color: rgb(148, 8, 8);
  font-weight: 600;
  padding: 1rem 0;
  margin-left: 5px;
}

.home .btn {
  margin-top: 1rem;
  position: absolute;
  line-height: 0;
  padding: 1.6rem 3rem;
  border-radius: 4em;
  transition: 0.5s;
  color: #fff;
  background: #2506ad;
  box-shadow: 0px 5px 18px rgba(48, 68, 247, 0.6);
  font-family: "Nunito", sans-serif;
}

.home .btn span {
  font-weight: 700;
  font-size: 1.7rem;
  letter-spacing: 0.1rem;
}

.home .btn i {
  margin-left: 0.3rem;
  font-size: 1.5rem;
  transition: 0.3s;
}

.home .btn:hover {
  background: #1a047e;
}

.home .btn:hover i {
  transform: translateX(5px);
}

/* social icons start */
.socials {
  position: relative;
  margin-top: 9rem;
}

.socials .social-icons {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.socials .social-icons li {
  display: inline-block;
  margin-bottom: 14px;
}

.social-icons a {
  font-size: 2rem;
  display: inline-block;
  line-height: 44px;
  color: #00d9ff;
  background-color: #09011b;
  width: 44px;
  height: 44px;
  text-align: center;
  margin-right: 8px;
  border-radius: 100%;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.social-icons a:active,
.social-icons a:focus,
.social-icons a:hover {
  color: #fff;
  background-color: #0685da;
}

.social-icons a.github:hover {
  background-color: #0e0e0e;
}

.social-icons a.twitter:hover {
  background-color: #00aced;
}

.social-icons a.linkedin:hover {
  background-color: #007bb6;
}

.social-icons a.dev:hover {
  background-color: #070707;
}

.social-icons a.instagram:hover {
  background-color: #ee00da;
}

/* social icons end */

/* hero media queries starts*/
@media (max-width: 450px) {
  .home .btn {
    margin: 4rem 0;
  }

  .socials {
    margin-top: 12rem;
  }

  .home .image img {
    margin-top: -12rem;
  }

  .home .content p {
    font-size: 2.2rem;
  }

  .home .content p span {
    font-size: 2.2rem;
  }
}

/* hero media queries ends*/
/* hero section end */